h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1.45;
  font-style: normal;
  font-size: inherit;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 1em 0 0.65em 0;
}

h5 {
  font-weight: 400;
}
h6 {
  font-weight: 600;
}

p {
  font-size: 1.125em;
  margin: 1em 0;
  font-weight: 300;
  line-height: 1.45;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

li {
  font-size: 1em;
  margin: 0 0 1em 0;
  font-weight: 300;
  line-height: 1.45;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

a {
  text-decoration: none;
}
